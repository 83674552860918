import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BrowsingCriteria, Domain, ProductLineAttribute } from 'types/domainService';
import { EntityProduct } from 'types/productService';
import { BrowsingCriteriaAnswers, BrowsingCriteriaQuestionAnswers } from 'types/cartService';
import { DOMAIN_BY_ID_AND_DATE } from 'api/domainService';
import apolloClient from 'api/apolloClient';
import { ProductLineId } from 'types/productLineId';

export const INITIAL_DOMAIN_OBJECT: Readonly<Domain> = Object.freeze({
  id: '',
  name: '',
  logo: '',
  shortName: '',
  properties: {
    effectiveDatesAmount: 0,
    retroActiveDaysAmount: 0,
  },
  browsingCriteriaList: [],
});
export class DomainStore {
  domain: Domain = { ...INITIAL_DOMAIN_OBJECT };
  loading = true;

  public get productLinesIds(): ProductLineId[] {
    return this.domain.browsingCriteriaList.map((browsingCriteria) => browsingCriteria.productLineId);
  }

  public findPlanTilesAttributes(productLineId: string): ProductLineAttribute[] {
    const browsingCriteria = this.domainProductLineBrowsingCriteriaById(productLineId);
    const mainProductLineAttrs = browsingCriteria?.productLineAttributes.main;
    return mainProductLineAttrs?.filter((productLineAttribute) => productLineAttribute.planTile) || [];
  }

  public findAttributesByProductLine(productLineId: string): ProductLineAttribute[] {
    const browsingCriteria = this.domainProductLineBrowsingCriteriaById(productLineId);
    return browsingCriteria?.productLineAttributes.main || [];
  }

  // eslint-disable-next-line class-methods-use-this
  public findAttributeValue(entityProduct: EntityProduct, pLineAttrFromDomain: ProductLineAttribute): string {
    const productValue = entityProduct.product.productAttributes.find(
      (attr) => attr.productLineAttribute.id === pLineAttrFromDomain.id,
    );
    if (pLineAttrFromDomain.type === 'HELPFUL_LINK') {
      // for hepful link planStatus display value is empty, so we have to use value. TODO: improve it during product creation redesign
      return productValue ? productValue.value : '';
    }
    return productValue ? productValue.displayValue : '';
  }

  // Use domain configuration to receive browsing criteria questions
  // Then using browsing criteria answers from cartItem build text that represent selected answers
  public buildBrowsingCriteria(productLineId: string, browsingCriteriaAnswers: BrowsingCriteriaAnswers): string {
    const browsingCriteria = this.domainProductLineBrowsingCriteriaById(productLineId);
    const domainQuestions = browsingCriteria?.questions;

    // If domain has browsing criteria for this product line then this method will collect there description
    if (domainQuestions) {
      const browsingCriteriaQuestionsWithAnswers = (browsingCriteriaAnswers[
        productLineId
      ] as BrowsingCriteriaQuestionAnswers).questions;
      const browsingCriteriaText = Object.keys(browsingCriteriaQuestionsWithAnswers)
        .map((key) => {
          const questionValue = browsingCriteriaQuestionsWithAnswers[key];

          const workflows = domainQuestions
            .filter((domainQuestion) => domainQuestion.questionName === key)
            .map((domainQuestion) => domainQuestion.questionWorkflows)[0];

          const inputOptions = workflows
            .filter((workflow) => workflow.workflowName === 'browseWorkflow')
            .map((workflow) => workflow.inputOptions)[0];

          if (inputOptions.length === 0) {
            return '';
          }
          if (inputOptions.length === 1) {
            // eslint-disable-next-line no-template-curly-in-string
            if (inputOptions[0].displayDetailValue.includes('`${value}')) {
              inputOptions[0].displayDetailValue = inputOptions[0].displayDetailValue
                // eslint-disable-next-line no-template-curly-in-string
                .replace('`${value}', browsingCriteriaQuestionsWithAnswers[key])
                .replace('`', '');
            }
            return inputOptions[0].displayDetailValue;
          }
          return inputOptions.filter((option) => option.value.toString() === questionValue.toString())[0]
            .displayDetailValue;
        })
        .join(' | ');

      return browsingCriteriaText;
    }
    return '';
  }

  public hasProductLineBrowsingCriteria(productLineId: string): boolean {
    const browsingCriteria = this.domainProductLineBrowsingCriteriaById(productLineId);
    return (browsingCriteria && browsingCriteria.questions.length > 0) || false;
  }

  private domainProductLineBrowsingCriteriaById(productLineId: string): BrowsingCriteria | undefined {
    return this.domain.browsingCriteriaList.find(
      (browsingCriteria) => browsingCriteria.productLineId === productLineId,
    );
  }

  public async getDomainById(domainId: string, date?: string): Promise<void> {
    const res = await apolloClient.query<{ getDomainById: Domain }>({
      query: DOMAIN_BY_ID_AND_DATE,
      variables: { domainId, date: date || null, marketType: 'INDIVIDUAL' },
    });

    runInAction(() => {
      this.domain = res.data.getDomainById;
      this.loading = false;
    });
  }

  constructor() {
    makeObservable(this, {
      domain: observable,
      loading: observable,
      findPlanTilesAttributes: action.bound,
      findAttributesByProductLine: action.bound,
      findAttributeValue: action.bound,
      buildBrowsingCriteria: action.bound,
      hasProductLineBrowsingCriteria: action.bound,
      getDomainById: action.bound,
      productLinesIds: computed,
    });
  }
}
