import { TableCell } from '@insurancemenu/imenu-components-lib';
import React from 'react';
import { createStyles } from './styles';

interface Props {
  title?: string;
  data?: string;
  customLayout?: React.ReactNode;
  width?: string;
}

const TiersTableCell: React.FC<Props> = ({ title, data, customLayout, width }) => {
  // Common data
  const classes = createStyles();
  return (
    <TableCell className={classes.tierCell} width={width}>
      {customLayout ?? (
        <div>
          <h6 className={classes.title}>{title}</h6>
          <p className={classes.text}> {data}</p>
        </div>
      )}
    </TableCell>
  );
};

export default TiersTableCell;
