import React from 'react';
import { hot } from 'react-hot-loader';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import IndividualFormPage from 'pages/IndividualFormPage';
import ConfirmationPage from 'pages/ConfirmationPage';

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/form-confirm" component={ConfirmationPage} />
          <Route path="/" component={IndividualFormPage} />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  );
};

declare const module: Record<string, unknown>;

export default hot(module)(App);
