import { gql } from '@apollo/client';

export const DOMAIN_BY_ID_AND_DATE = gql`
  query DOMAIN_SERVICE_getDomainId($domainId: String!, $date: String, $marketType: MarketType) {
    getDomainById(domainId: $domainId, date: $date, marketType: $marketType) {
      id
      name
      shortName
      logo
      browsingCriteriaList {
        productLineId
        productLineAttributes
        questions {
          questionName
          enableDefaultWorkflows
          hasPortfolioLevelQuestion
          questionWorkflows {
            workflowName
            enable
            title
            descriptionHtml
            answerType
            inputType
            inputOptions {
              value
              displayValue
              displayDetailValue
              validation
              hidden
            }
            hidden
          }
        }
      }
    }
  }
`;
