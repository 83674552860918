import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';

const styles = {
  pageWrapper: {
    backgroundColor: colors.neutral[100],
    '@media (max-width: 1240px) ': {
      padding: '0 2.5%',
    },
    '@media (max-width: 1150px) ': {
      padding: '0 5%',
    },
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
