import { Button, colors, TableRow } from '@insurancemenu/imenu-components-lib';
import React from 'react';
import { Tier } from 'types/formService';
import TiersTableCell from '../TiersTableCell';
import { createStyles } from './styles';

interface Props extends Tier {
  onEdit: (tier: Tier) => void;
  onDelete: (idToDelete: number) => void;
}

const TiersTableRow: React.FC<Props> = ({
  id,
  firstName,
  middleName,
  lastName,
  relationship,
  dateOfBirth,
  ssn,
  gender,
  onEdit,
  onDelete,
}) => {
  const classes = createStyles();
  return (
    <TableRow className={classes.tier}>
      <TiersTableCell title="Relationship" data={relationship} width="13rem" />
      <TiersTableCell title="Name" data={`${firstName} ${middleName} ${lastName}`} width="14rem" />
      <TiersTableCell title="Date of birth" data={dateOfBirth} width="10.9rem" />
      <TiersTableCell title="Gender" data={gender} width="9rem" />
      <TiersTableCell title="SSN" data={ssn} width="10rem" />
      <TiersTableCell
        width="2rem"
        customLayout={
          <>
            <Button
              type="text"
              color="primary"
              onClick={
                (): void => onEdit({ id, firstName, middleName, lastName, relationship, dateOfBirth, ssn, gender })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              Edit
            </Button>
            <Button type="text" color="custom" customColor={colors.red[500]} onClick={(): void => onDelete(id)}>
              Delete
            </Button>
          </>
        }
      />
    </TableRow>
  );
};

export default TiersTableRow;
