import React from 'react';
import { createStyles } from './styles';

interface Props {
  title: string;
}

const Section: React.FC<Props> = ({ title, children }) => {
  const classes = createStyles();
  return (
    <section className={classes.section}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.contentWrapper}>{children}</div>
    </section>
  );
};

export default Section;
