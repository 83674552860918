/* eslint-disable class-methods-use-this */
import apolloClient from 'api/apolloClient';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { EnrollmentPeriod, EnrollmentType, ProductType, USER_ACTIONS } from 'types/enrollmentService';
import {
  APPLY_USER_ACTION,
  COMPLETE_ENROLLMENT,
  FORM_EMAIL_ME,
  GET_ENROLLMENT_PERIOD_BY_ID,
} from 'api/enrollmentService';

export const INITIAL_ENROLLMENT_OBJECT = Object.freeze({
  id: '',
  proposals: [
    {
      cartId: '',
      cartVersion: 0,
      name: '',
      productLines: [
        {
          id: '',
          name: '',
          productType: ProductType.NEW_SALE,
          confirmedPortfolioId: '',
        },
      ],
      confirmed: false,
    },
  ],
  groupId: '',
  hasConfirmedProposal: false,
  effectiveDate: '',
  productLineIds: [],
  stage: '',
  type: EnrollmentType.NEW_SALE,
  checklist: {
    checklistOptions: [],
  },
});

export class EnrollmentStore {
  // Data
  enrollmentPeriod: EnrollmentPeriod = INITIAL_ENROLLMENT_OBJECT;

  // Methods
  async getEnrollmentPeriod(periodId: string): Promise<void> {
    const res = await apolloClient.query<{ getEnrollmentPeriod: EnrollmentPeriod }>({
      query: GET_ENROLLMENT_PERIOD_BY_ID,
      fetchPolicy: 'no-cache',
      variables: { periodId },
    });
    runInAction(() => {
      if (res.data) {
        this.enrollmentPeriod = res.data.getEnrollmentPeriod;
      }
    });
  }

  // Methods
  async emailForm(periodId: string): Promise<void> {
    await apolloClient.mutate<{ periodId: string }>({
      mutation: FORM_EMAIL_ME,
      fetchPolicy: 'no-cache',
      variables: { periodId },
    });
  }

  async applyUserAction(periodId: string, userAction: USER_ACTIONS): Promise<void> {
    await apolloClient.mutate({
      mutation: APPLY_USER_ACTION,
      variables: { periodId, userAction },
    });
  }

  async completeEnrollment(periodId: string): Promise<void> {
    await apolloClient.mutate({
      mutation: COMPLETE_ENROLLMENT,
      variables: { periodId },
    });
  }

  constructor() {
    makeObservable(this, {
      enrollmentPeriod: observable,
      applyUserAction: action.bound,
      emailForm: action.bound,
      completeEnrollment: action.bound,
    });
  }
}
