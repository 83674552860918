import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';

const styles = {
  section: {
    padding: { bottom: '4rem' },
    '&:last-of-type': {
      paddingBottom: 0,
    },
    '&:first-of-type': {
      paddingTop: 0,
    },
  },

  title: {
    font: { weight: 700, size: '2.8rem', lineHeight: '4rem' },
    color: colors.neutral[900],
    margin: [0],
    borderBottom: ['1px', 'solid', colors.neutral[300]],
    paddingBottom: '1.6rem',
  },
  contentWrapper: {
    padding: { top: '4rem', left: '2rem' },

    '@media(max-width:1200px)': {
      paddingLeft: '0rem',
    },
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
