import { action, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'store/rootStore';
import { formUtils } from '@insurancemenu/imenu-components-lib';
import { FormObjectFromJSON } from '@insurancemenu/imenu-components-lib/dist/types';
import { FormInstanceType, IndividualDynamicFormController, IndividualCustomFields } from 'types/formService';
import { USER_ACTIONS } from 'types/enrollmentService';

export default class IndividualFormStore {
  rootStore: RootStore;
  formInstance: FormInstanceType | null = null;
  dynamicForm: undefined | IndividualDynamicFormController;
  loading = false;

  setLoading(loadingStatus: boolean): void {
    this.loading = loadingStatus;
  }

  createFormController(formObject: FormObjectFromJSON): void {
    this.setLoading(true);
    const params = formUtils.createDynamicFormControllerParamsFromJSON<IndividualCustomFields>(formObject);
    this.dynamicForm = new formUtils.DynamicFormController<IndividualCustomFields>(
      params.fields,
      params.enableMastersObject,
      params.customDynamicFields,
    );
    this.setLoading(false);
  }

  async getFormInstance(enrollmentPeriodId: string): Promise<void> {
    this.setLoading(true);
    const res = await this.rootStore.formStore.requestFormInstance(enrollmentPeriodId);
    runInAction(() => {
      // eslint-disable-next-line prefer-destructuring
      this.formInstance = res.data.getIndividualFormInstances[0];
      this.setLoading(false);
    });
  }

  async setAnswers(complete: boolean, periodId?: string): Promise<void> {
    this.setLoading(true);
    if (this.formInstance && this.dynamicForm) {
      try {
        await this.rootStore.formStore.requestSetAnswers({
          formInstanceId: this.formInstance.id,
          answers: {
            common: this.dynamicForm.getFormDataObject(),
            custom: this.dynamicForm.getCustomFormFieldsObject(),
          },
          complete,
          formType: 'INDIVIDUAL_FORM',
        });
      } finally {
        this.setLoading(false);
      }

      runInAction(async () => {
        if (complete && periodId) {
          await this.requestApplyUserAction(periodId);
        }
      });
    }
  }

  async requestEmailForm(): Promise<void> {
    this.setLoading(true);
    if (this.formInstance) {
      await this.rootStore.enrollmentStore.emailForm(this.formInstance.enrollmentPeriodId);
    }
    runInAction(() => {
      this.setLoading(false);
    });
  }

  async requestApplyUserAction(periodId: string): Promise<void> {
    await this.rootStore.enrollmentStore.applyUserAction(periodId, USER_ACTIONS.COMPLETE_INDIVIDUAL);
  }

  async requestCompleteEnrollment(periodId: string): Promise<void> {
    await this.rootStore.enrollmentStore.completeEnrollment(periodId);
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.dynamicForm = undefined;

    makeObservable(this, {
      dynamicForm: observable,
      loading: observable,
      setLoading: action.bound,
      createFormController: action.bound,
      setAnswers: action.bound,
      getFormInstance: action.bound,
      requestApplyUserAction: action.bound,
      requestEmailForm: action.bound,
      requestCompleteEnrollment: action.bound,
    });
  }
}
