import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';

const styles = {
  content: { padding: '8rem 20rem ' },
  title: {
    font: {
      size: '5rem',
      weight: 900,
      lineHeight: '6rem',
    },
    color: colors.neutral[900],
  },
  text: {
    paddingLeft: '1rem',
    font: {
      size: '2rem',
      weight: 500,
      lineHeight: '3rem',
    },
    color: colors.neutral[800],
    maxWidth: '70rem',
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
