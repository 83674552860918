import { createContext, useContext } from 'react';
import { configure } from 'mobx';
import { NotificationStore } from './general/notificationStore';

import { EnrollmentStore } from './domain/enrollmentStore';
import { DomainStore } from './domain/domainStore';
import { CartStore } from './domain/cartStore';
import { ProductStore } from './domain/productStore';
import { FormStore } from './domain/formStore';
import DependentInfoStore from './ui-stores/individual-form/dependentInfoStore';
import IndividualFormStore from './ui-stores/individual-form/individualFormStore';
import { PlanSummaryStore } from './ui-stores/individual-form/planSummaryStore';

configure({
  useProxies: 'never',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
});

export class RootStore {
  // General
  notificationStore: NotificationStore;

  // Domain
  cartStore: CartStore;
  enrollmentStore: EnrollmentStore;
  domainStore: DomainStore;
  productStore: ProductStore;
  formStore: FormStore;

  // UI stores
  individualFormStore: IndividualFormStore;
  planSummaryStore: PlanSummaryStore;
  dependentInfoStore: DependentInfoStore;

  constructor() {
    // General stores
    this.notificationStore = new NotificationStore();

    // Domain stores
    this.enrollmentStore = new EnrollmentStore();
    this.domainStore = new DomainStore();
    this.cartStore = new CartStore();
    this.productStore = new ProductStore();
    this.formStore = new FormStore();

    // UI stores
    this.individualFormStore = new IndividualFormStore(this);
    this.planSummaryStore = new PlanSummaryStore(this);
    this.dependentInfoStore = new DependentInfoStore(this);
  }
}

const RootContext = createContext(new RootStore());

export const useRootStore = (): RootStore => {
  return useContext(RootContext);
};

export { RootContext };
