import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@insurancemenu/imenu-components-lib';
import { ArrowBack } from '@insurancemenu/imenu-components-lib/dist/icons';
import { useRootStore } from 'store/rootStore';
import SaveChangesModal from '../SaveChangesModal';
import { createStyles } from './styles';

const PageHeader = (): JSX.Element => {
  // Common data
  const history = useHistory();

  // Mobx store
  const { individualFormStore } = useRootStore();

  // React state
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);

  // Component actions
  function openSaveChangesModal(): void {
    setShowSaveChangesModal(true);
  }

  function onRedirect(): void {
    setShowSaveChangesModal(false);
    history.goBack();
  }

  async function onSaveChanges(): Promise<void> {
    await individualFormStore.requestEmailForm();
    // await individualFormStore.setAnswers(false);
    history.goBack();
  }

  const classes = createStyles();
  return (
    <>
      <header className={classes.header}>
        <Button
          leftIconProp={<ArrowBack />}
          type="text"
          size="medium"
          onClick={openSaveChangesModal}
          className={classes.backButton}
        >
          Back
        </Button>
        <h1 className={classes.title}>Application Form</h1>
      </header>
      <SaveChangesModal show={showSaveChangesModal} onClose={onRedirect} onAction={onSaveChanges} />
    </>
  );
};

export default PageHeader;
