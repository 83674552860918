import React from 'react';
import { Spinner } from '@insurancemenu/imenu-components-lib';
import { createStyles } from './styles';

interface FormSpinnerProps {
  display: boolean;
}

const FormsSpinner: React.FC<FormSpinnerProps> = (props) => {
  const classes = createStyles();
  return props.display ? (
    <div className={classes.spinnerWrapper}>
      <Spinner />
    </div>
  ) : null;
};

export default FormsSpinner;
