import { ProductLineId } from 'types/productLineId';

export interface EnrollmentPeriod {
  id: string;
  proposals: Proposal[];
  groupId: string;
  hasConfirmedProposal: boolean;
  effectiveDate: string;
  productLineIds: ProductLineId[];
  stage: string;
  type: EnrollmentType;
  checklist?: Checklist;
}

export interface Proposal {
  cartId: string;
  cartVersion: number;
  name: string;
  productLines: ProductLine[];
  confirmed: boolean;
}

export interface ProductLine {
  id: string;
  productType: ProductType;
  confirmedPortfolioId: string | null;
}

export enum EnrollmentType {
  NEW_SALE = 'NEW_SALE',
  RENEWAL = 'RENEWAL',
}

export enum ProductType {
  NEW_SALE = 'NEW_SALE',
  RENEWAL_CHANGE_CARRIER_PRODUCT = 'RENEWAL_CHANGE_CARRIER_PRODUCT',
  CANCEL_COVERAGE = 'CANCEL_COVERAGE',
  RENEWAL_SAME_PRODUCT = 'RENEWAL_SAME_PRODUCT',
  DONT_PROVIDE = 'DONT_PROVIDE',
}

export interface Checklist {
  checklistOptions: ChecklistOption[];
}

export enum ChecklistCompletionStatuses {
  COMPLETED = 'COMPLETED',
  READY_TO_BEGIN = 'READY_TO_BEGIN',
  CANNOT_COMPLETE_YET = 'CANNOT_COMPLETE_YET',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum ChecklistCard {
  PROPOSAL = 'PROPOSAL',
  COMPANY_INFO = 'COMPANY_INFO',
  EMPLOYER_CONTRIBUTION = 'EMPLOYER_CONTRIBUTION',
  EMPLOYEE_ELECTIONS = 'EMPLOYEE_ELECTIONS',
  FORMS = 'FORMS',
  REVIEW_SUBMIT = 'REVIEW_SUBMIT',
  DOWNLOAD = 'DOWNLOAD',
}

export enum ChecklistButtonStateText {
  VIEW_EDIT = 'VIEW_EDIT',
  VIEW = 'VIEW',
  UNAVAILABLE = 'UNAVAILABLE',
  CONTINUE = 'CONTINUE',
  DOWNLOAD = 'DOWNLOAD',
  REVIEW_SUBMIT = 'REVIEW_SUBMIT',
}

export interface ChecklistOption {
  card: ChecklistCard;
  buttonState: string;
  completionStatus: ChecklistCompletionStatuses;
}

export interface InputCart {
  cartId: string;
  cartVersion: number;
  productLines: ProductLine[];
}

export interface InputProposal {
  cartId: string;
  cartVersion: number;
  productLines: ProductLine[];
  groupName: string;
}

export enum ProposalAction {
  CONFIRM = 'CONFIRM',
  UPDATE = 'UPDATE',
  SWITCH = 'SWITCH',
}

export enum USER_ACTIONS {
  COMPLETE_PROPOSAL = 'COMPLETE_PROPOSAL',
  COMPLETE_COMPANY_INFO = 'COMPLETE_COMPANY_INFO',
  COMPLETE_CONTRIBUTION = 'COMPLETE_CONTRIBUTION',
  COMPLETE_ELECTIONS = 'COMPLETE_ELECTIONS',
  COMPLETE_FORMS = 'COMPLETE_FORMS',
  COMPLETE_REVIEW_SUBMIT = 'COMPLETE_REVIEW_SUBMIT',
  COMPLETE_INDIVIDUAL = 'COMPLETE_INDIVIDUAL',
}
