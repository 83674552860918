import { colors } from '@insurancemenu/imenu-components-lib';
import { createUseStyles } from 'react-jss';

const styles = {
  modalHeader: {
    padding: '3.2.rem 0rem 2.4rem 0rem',
    textAlign: 'center',
    borderBottom: `1px solid ${colors.neutral[400]}`,
  },
  title: {
    font: { size: '2.4rem', lineHeight: '3.2rem', weight: 700 },
    letterSpacing: '0.02rem',
    color: colors.neutral[900],
  },
  modalBody: {
    padding: '2.8rem 0rem 1.5rem 0rem',
    borderBottom: `1px solid ${colors.neutral[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  regularField: { flex: '0.2 1 20rem', marginBottom: '4.4rem' },
  longField: { flex: '0.4 1 30.4rem', marginBottom: '4.4rem' },
  modalFooter: { paddingTop: '4.3rem', display: 'flex', justifyContent: 'center' },
  actionButton: { marginLeft: '1.6rem' },
};

const createStyles = createUseStyles(styles);

export { styles, createStyles };
