import React, { useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import uniqid from 'uniqid';
import { Button } from '@insurancemenu/imenu-components-lib';
import { Add } from '@insurancemenu/imenu-components-lib/dist/icons';
import { IndividualDynamicFormController, Tier } from 'types/formService';
import { useRootStore } from 'store/rootStore';
import TiersTable from '../TiersTable';
import DependentInfoModal from '../DependentInfoModal';
import { createStyles } from './styles';

interface Props {
  formController?: IndividualDynamicFormController;
}

const EligibleTiers: React.FC<Props> = observer(({ formController }) => {
  // Mobx store
  const { dependentInfoStore } = useRootStore();

  // Common data
  const addedTiers = formController?.customFields.dependents.value?.map((tier) => toJS(tier)).slice() || [];
  const spousesNumber = addedTiers.filter((tier) => tier.relationship === 'Spouse').length;
  const childrenNumber = addedTiers.filter((tier) => tier.relationship === 'Child').length;

  // React state
  const [showDependentInfoModal, setShowDependentInfoModal] = useState(false);
  const [dependentInfoModalMode, setDependentInfoModalMode] = useState<'ADD' | 'EDIT'>('ADD');

  // Component actions
  function openDependentInfoModal(): void {
    setShowDependentInfoModal(true);
  }

  function closeDependentInfoModal(): void {
    setShowDependentInfoModal(false);
  }

  function turnEditMode(tierToEdit: Tier): void {
    dependentInfoStore.setActiveTierId(tierToEdit.id);
    dependentInfoStore.form.populateFields(tierToEdit);
    setDependentInfoModalMode('EDIT');
  }

  function turnAddMode(): void {
    dependentInfoStore.setActiveTierId(undefined);
    dependentInfoStore.form.resetForm();
    setDependentInfoModalMode('ADD');
  }

  function onAddTier(): void {
    turnAddMode();
    openDependentInfoModal();
  }

  function onEditTier(tier: Tier): void {
    turnEditMode(tier);
    openDependentInfoModal();
  }

  function onDeleteTier(idToDelete: number): void {
    formController?.customFields.dependents.updateValue(
      formController.customFields.dependents.value.filter((dep) => dep.id !== idToDelete),
    );
  }

  function onSaveTier(): void {
    if (dependentInfoModalMode === 'ADD') {
      const newTier: Tier = ({
        id: uniqid(),
        ...dependentInfoStore.form.getFormDataObject(),
      } as unknown) as Tier;
      formController?.customFields.dependents.updateValue([...addedTiers, newTier]);
    } else {
      const tierUnderUpdate = addedTiers.find((depObj: Tier) => depObj.id === dependentInfoStore.activeTierId);
      const indexOfTierUnderUpdate = tierUnderUpdate && addedTiers.indexOf(tierUnderUpdate);
      const newAddedTiers = [...addedTiers];
      if (tierUnderUpdate && indexOfTierUnderUpdate !== undefined) {
        newAddedTiers[indexOfTierUnderUpdate] = {
          ...dependentInfoStore.form.getFormDataObject(),
          id: tierUnderUpdate.id,
        } as Tier;
        formController?.customFields.dependents.updateValue([...newAddedTiers]);
      }
    }
    closeDependentInfoModal();
  }

  const classes = createStyles();
  return (
    <div className={classes.eligibleTiersWrapper}>
      <Button
        leftIconProp={<Add />}
        color="primary"
        size="large"
        onClick={onAddTier}
        disabled={childrenNumber >= 4 && spousesNumber >= 1}
      >
        Add Family Member To Plan
      </Button>
      {addedTiers?.length > 0 && <TiersTable tiers={addedTiers} onEditTier={onEditTier} onDeleteTier={onDeleteTier} />}
      <DependentInfoModal
        spousesNumber={spousesNumber}
        childrenNumber={childrenNumber}
        show={showDependentInfoModal}
        onClose={closeDependentInfoModal}
        onAction={onSaveTier}
        mode={dependentInfoModalMode}
      />
    </div>
  );
});

export default EligibleTiers;
