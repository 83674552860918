import { gql } from '@apollo/client';

export const GET_CART_BY_ID = gql`
  query CART_SERVICE_getCartById($groupId: String, $id: String) {
    getCartById(groupId: $groupId, id: $id) {
      id
      lastUpdateTime
      creationDateTime
      browsingCriteria
      cartItems {
        portfolioId
        productLineId
        browsingCriteria
        confirmed
      }
    }
  }
`;
