import { action, makeObservable } from 'mobx';
import apolloClient from 'api/apolloClient';
import { GET_FORM_INSTANCE, SET_ANSWERS } from 'api/formService';
import { ApolloQueryResult } from '@apollo/client';
import { FormInstanceType, SetAnswersParamsType } from 'types/formService';

export class FormStore {
  // eslint-disable-next-line class-methods-use-this
  async requestFormInstance(
    enrollmentPeriodId: string,
  ): Promise<ApolloQueryResult<{ getIndividualFormInstances: FormInstanceType[] }>> {
    return apolloClient.query<{ getIndividualFormInstances: FormInstanceType[] }>({
      query: GET_FORM_INSTANCE,
      fetchPolicy: 'no-cache',
      variables: { enrollmentPeriodId },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async requestSetAnswers(params: SetAnswersParamsType): Promise<void> {
    await apolloClient.mutate({
      mutation: SET_ANSWERS,
      fetchPolicy: 'no-cache',
      variables: params,
    });
  }

  constructor() {
    makeObservable(this, {
      requestFormInstance: action.bound,
      requestSetAnswers: action.bound,
    });
  }
}
