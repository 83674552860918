import React from 'react';
import { observer } from 'mobx-react-lite';
import { formatCurrency } from '@insurancemenu/imenu-components-lib/dist/helpers';
import { useRootStore } from 'store/rootStore';
import { EntityProduct } from 'types/productService';
import { SelectedPlanItems } from 'store/ui-stores/individual-form/planSummaryStore';
import { createStyles } from './styles';

interface Props {
  item: SelectedPlanItems;
}

const PlanCard = observer(
  ({ item }: Props): JSX.Element => {
    const classes = createStyles();
    // Mobx store
    const rootStore = useRootStore();
    const { domainStore } = rootStore;

    return (
      <div className={classes.cartItemContentWrapper} key={`planCard_${item.cartItem.portfolioId}`}>
        {item.entityPortfolio &&
          item.entityPortfolio.entityProducts.map((entityProduct: EntityProduct) => (
            <div key={`productCard_${entityProduct.product.id}`}>
              <header>
                <div className={classes.sponsorLogo}>
                  <img
                    width="auto"
                    height="16rem"
                    src={entityProduct.product.carrier.logo}
                    alt={entityProduct.product.carrier.name}
                  />
                </div>
                <h2 className={classes.portfolioName}>{entityProduct.product.name}</h2>
              </header>
              <div
                key={`${item.entityPortfolio.portfolio.id}_${entityProduct.product.id}`}
                className={classes.productContentWrapper}
              >
                <div className={classes.portfolioMainData}>
                  <ul className={classes.rates}>
                    {entityProduct.rates.map((rate) => (
                      <li key={`${rate.id}_${rate.value}`} className={classes.rateWrapper}>
                        <span className={classes.rateName}>{rate.displayKey}</span>
                        <span className={classes.rateValue}>{formatCurrency(rate.value.amount)}</span>
                      </li>
                    ))}
                  </ul>
                  {domainStore.hasProductLineBrowsingCriteria(item.cartItem.productLineId) &&
                    item.cartItem.browsingCriteria && (
                      <div className={classes.browsingCriteria}>
                        {domainStore.buildBrowsingCriteria(item.cartItem.productLineId, item.cartItem.browsingCriteria)}
                      </div>
                    )}
                </div>
                <div className={classes.planTiles}>
                  {domainStore.findPlanTilesAttributes(item.cartItem.productLineId).map((planTile) => {
                    return (
                      <div key={`${planTile.id}_${entityProduct.product.id}`} className={classes.attributeWrapper}>
                        <div className={classes.planTileName}>{planTile.name}</div>
                        <div className={classes.planTileValue}>
                          {domainStore.findAttributeValue(entityProduct, planTile)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  },
);

export default PlanCard;
