import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, ServerError } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';
import '../assets/css/toast.css';

const errorLink = onError(({ networkError }) => {
  if (networkError && (networkError as ServerError)?.statusCode === 406) {
    const errorMessage = 'result' in networkError ? networkError.result.errors[0].message : 'Unexpected error';
    toast.error(`${errorMessage}`, {
      toastId: 'error-toast',
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  if (networkError) {
    const errorMessage = 'result' in networkError ? networkError.result.errors[0].message : 'Unexpected error';
    toast.error(`${networkError} Error message: ${errorMessage}`, {
      toastId: 'error-toast',
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const customFetch = (_, options): Promise<Response> => {
  const body = JSON.parse(options.body);
  // Follow the naming convention for all the queries created on front-end. The name should go:
  // SERVICE_NAME_nameOfQuery . The service should also be listed here, in customFetch function.
  if (body.operationName.includes('DOMAIN_SERVICE_')) {
    return fetch(`https://${process.env.REACT_APP_API_BASE_URL}/domain/Prod/graphqlNoAuth`, options);
  }
  if (body.operationName.includes('CART_SERVICE_')) {
    return fetch(`https://${process.env.REACT_APP_API_BASE_URL}/cart/Prod/graphqlNoAuth`, options);
  }
  if (body.operationName.includes('ENROLLMENT_SERVICE_')) {
    return fetch(`https://${process.env.REACT_APP_API_BASE_URL}/enrollment/Prod/graphqlNoAuth`, options);
  }
  if (body.operationName.includes('PRODUCT_SERVICE_')) {
    return fetch(`https://${process.env.REACT_APP_API_BASE_URL}/product/Prod/graphQl`, options);
  }
  if (body.operationName.includes('FORM_SERVICE_')) {
    return fetch(`https://${process.env.REACT_APP_API_BASE_URL}/form/Prod/graphqlNoAuth`, options);
  }
  throw new Error('Unknown server requested!');
};
const link = createHttpLink({ fetch: customFetch });

const additiveLink = ApolloLink.from([errorLink, link]);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
apolloClient.setLink(additiveLink);

export default apolloClient;
