import { Modal, Button } from '@insurancemenu/imenu-components-lib';
import { NotificationImportant } from '@insurancemenu/imenu-components-lib/dist/icons';
import React from 'react';
import { createStyles } from './styles';

interface Props {
  show: boolean;
  onClose: () => void;
  onAction: () => void;
}

const SaveChangesModal = ({ show, onClose, onAction }: Props): JSX.Element => {
  const classes = createStyles();
  return (
    <Modal show={show} onClose={onClose} className={classes.modal} closable={false}>
      <NotificationImportant width="5.6rem" className={classes.icon} />
      <p className={classes.text}>Complete application later?</p>
      <footer className={classes.modalFooter}>
        <Button type="outlined" onClick={onClose} size="large">
          Skip
        </Button>
        <Button color="primary" onClick={onAction} size="large">
          Email Me Application
        </Button>
      </footer>
    </Modal>
  );
};

export default SaveChangesModal;
