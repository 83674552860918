/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, formUtils } from '@insurancemenu/imenu-components-lib';
import { useQueryParams } from 'utils/useQueryParams';
import FormsSpinner from 'components/common/FormSpinner';
import { useRootStore } from 'store/rootStore';
import Section from 'components/individual-form/Section';
import { runInAction } from 'mobx';
import SubmitEnrollmentModal from 'components/individual-form/SubmitEnrollmentModal';
import EligibleTiers from '../EligibleTiers';
import { createStyles } from './styles';

const Form = observer(
  (): JSX.Element => {
    // React local state
    const [showSubmitModal, setShowSubmitModal] = React.useState(false);
    // Common data
    const query = useQueryParams();
    const periodId = query.get('periodId');
    const history = useHistory();

    // Mobx store
    const {
      individualFormStore: {
        formInstance,
        getFormInstance,
        setAnswers,
        createFormController,
        dynamicForm,
        loading,
        requestEmailForm,
        requestCompleteEnrollment,
      },
    } = useRootStore();

    // React effects
    useEffect(() => {
      if (periodId) {
        getFormInstance(periodId);
      }
    }, []);

    useEffect(() => {
      if (formInstance) {
        createFormController(formInstance.form);
      }
    }, [formInstance]);

    useEffect(() => {
      if (formInstance && dynamicForm) {
        dynamicForm?.populateFields(formInstance.answers.common);
        dynamicForm?.populateCustomFields(formInstance.answers.custom);
      }
    }, [formInstance, dynamicForm]);

    // Component actions
    async function onSaveAndExit(): Promise<void> {
      await requestEmailForm();
      await setAnswers(false);
    }

    async function onCompleteForm(e: React.FormEvent): Promise<void> {
      e.preventDefault();
      if (dynamicForm?.isFormValid() && periodId) {
        await setAnswers(true, periodId);
        runInAction(() => {
          setShowSubmitModal(true);
        });
      }
    }

    function onSubmitModalClose(): void {
      setShowSubmitModal(false);
    }

    async function onSubmitModalAction(): Promise<void> {
      if (!periodId) {
        throw new Error('Could not Submit Enrollment Period without period id');
      }
      await requestCompleteEnrollment(periodId);
      runInAction(() => {
        setShowSubmitModal(false);
        history.push('/form-confirm');
      });
    }

    // Concomitant layout
    const CUSTOM_COMPONENTS = Object.freeze({
      dependents: <EligibleTiers key="dependents" formController={dynamicForm} />,
    });

    const classes = createStyles();
    return (
      <>
        <form onSubmit={onCompleteForm}>
          <div className={classes.formBody}>
            <div className={classes.innerWrapper}>
              <div>
                <h1 className={classes.formTitle}>{formInstance?.form.formTitle}</h1>
                <h3
                  className={classes.formDescription}
                  dangerouslySetInnerHTML={{ __html: formInstance?.form.description || '' }}
                />
              </div>
              {formInstance &&
                dynamicForm &&
                formInstance.form.sections.map((section) => {
                  return (
                    <Section title={section.title} key={section.title}>
                      {section.layout.map((entity) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        return formUtils.renderEntity(
                          entity,
                          dynamicForm,
                          formInstance.form.productLinesIds,
                          CUSTOM_COMPONENTS,
                        );
                      })}
                    </Section>
                  );
                })}
              <FormsSpinner display={loading} />
            </div>
          </div>
          <footer className={classes.footer}>
            <Button
              type="outlined"
              size="large"
              width="24rem"
              onClick={onSaveAndExit}
              className={classes.saveButton}
              disabled={loading}
            >
              Save & Exit
            </Button>
            <Button
              size="large"
              color={dynamicForm?.isFormValid() ? 'primary' : 'default'}
              width="24rem"
              htmlType="submit"
              disabled={!dynamicForm?.areAllFieldsValid() || loading}
            >
              Complete
            </Button>
          </footer>
        </form>
        <SubmitEnrollmentModal show={showSubmitModal} onClose={onSubmitModalClose} onAction={onSubmitModalAction} />
      </>
    );
  },
);

export default Form;
