import { action, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'store/rootStore';
import { CartItem } from 'types/cartService';
import { EntityPortfolio } from 'types/productService';

export interface SelectedPlanItems {
  id: string;
  cartItem: CartItem;
  entityPortfolio: EntityPortfolio;
  groupId: string;
}

export class PlanSummaryStore {
  rootStore: RootStore;

  items: SelectedPlanItems[] = []; // used as collective entity for data (from different services) that will be used to display plan
  loading = false;

  // Main method that collect data from BE
  public async populateSelectedPlanItems(groupId: string, cartId: string, domainId: string): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.rootStore.cartStore.getCartById(groupId, cartId),
      this.rootStore.domainStore.getDomainById(domainId),
    ]);

    await runInAction(async () => {
      // Copy basic types to avoid possible override
      const { cart } = this.rootStore.cartStore;

      // Get entity portfolios to display rates, plan tiles and browsing criteria
      await this.rootStore.productStore.requestEntityPortfolios(groupId, cart);
      runInAction(() => {
        this.items = [];
        const { entityPortfolios } = this.rootStore.productStore;
        cart.cartItems.forEach((cartItem: CartItem) => {
          const entityPortfolio = entityPortfolios.find(
            (ePortfolio: EntityPortfolio) => ePortfolio.portfolio.id === cartItem.portfolioId,
          );

          if (!entityPortfolio) {
            throw new Error(`There is no Portfolio with such id: ${cartItem.portfolioId} found!`);
          }
          this.items.push({
            id: cartItem.portfolioId,
            cartItem,
            groupId,
            entityPortfolio,
          });
        });
      });

      this.loading = false;
    });
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      items: observable,
      loading: observable,
      populateSelectedPlanItems: action.bound,
    });
  }
}
