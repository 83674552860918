import React from 'react';
import PageHeader from 'components/individual-form/PageHeader';
import Form from 'components/individual-form/Form';
import SelectedPlans from 'components/individual-form/SelectedPlans';
import { createStyles } from './styles';

const IndividualFormPage = (): JSX.Element => {
  const classes = createStyles();
  return (
    <div className={classes.pageWrapper}>
      <PageHeader />
      <main>
        <SelectedPlans />
        <Form />
      </main>
    </div>
  );
};

export default IndividualFormPage;
