import { colors } from '@insurancemenu/imenu-components-lib';
import { createUseStyles } from 'react-jss';

const styles = {
  cartItemContentWrapper: {
    backgroundColor: colors.neutral.white,
    margin: { left: 'auto', right: 'auto' },
    padding: {
      top: '3.2rem',
      bottom: '3.6rem',
    },
    display: 'flex',
    justifyContent: 'center',
    minHeight: '10rem',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'auto',
    borderTop: ['1px', 'solid', colors.neutral[300]],
  },
  cartItemContentWrapperChecked: {
    padding: '2rem 1.6rem',
    borderLeft: `16px solid ${colors.blue[600]}`,
    borderRight: `4px solid ${colors.blue[600]}`,
    borderBottom: `4px solid ${colors.blue[600]}`,
    borderTop: `4px solid ${colors.blue[600]}`,
  },
  productContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '2.4rem',
  },
  portfolioMainData: {
    flex: '1 0 30%',
    paddingRight: '2.4rem',
    boxSizing: 'border-box',
  },
  sponsorLogo: {
    zIndex: 5,
    position: 'relative',
  },
  portfolioName: {
    font: {
      size: '1.8rem',
      lineHeight: '2.4rem',
      weight: 700,
    },
    letterSpacing: '0.03rem',
    margin: '1.6rem 0',
    display: 'inline-flex',
    alignItems: 'center',
  },
  rates: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    listStyle: 'none',
    margin: 0,
  },
  rateWrapper: {
    '&:first-child': {
      paddingTop: '0.2rem',
      paddingBottom: '0.8rem',
    },
    '&:last-child': {
      paddingBottom: '1.8rem',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '0.8rem',
  },
  rateName: {
    font: {
      size: '1.4rem',
      lineHeight: '2rem',
      weight: 400,
    },
    letterSpacing: '0.02rem',
  },
  rateValue: {
    font: {
      size: '1.6rem',
      lineHeight: '2.4rem',
      weight: 700,
    },
    letterSpacing: '0.02rem',
    color: colors.green[600],
  },
  planTiles: {
    flex: '2 1 65%',
    borderLeft: `1px solid ${colors.neutral[300]}`,
    borderBottom: `1px dashed ${colors.neutral[300]}`,
    display: 'flex',
    flexDirection: 'column',

    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  browsingCriteria: {
    margin: '1.6rem 0',
    backgroundColor: colors.neutral[100],
    font: {
      size: '1.4rem',
      lineHeight: '2.0rem',
      weight: 400,
    },
    color: colors.neutral[700],
    letterSpacing: '0.02rem',
    padding: '0.8rem 1.2rem',
    borderRadius: '0.6rem',
  },
  attributeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.2rem',
  },
  planTileName: {
    width: '40%',
    letterSpacing: '0.02rem',
    font: {
      size: '1.4rem',
      lineHeight: '2.0rem',
      weight: 500,
    },
    padding: '0 2.4rem',
    boxSizing: 'border-box',
  },
  planTileValue: {
    flex: '1 1 55%',
    letterSpacing: '0.02rem',
    font: {
      size: '1.4rem',
      lineHeight: '2.0rem',
      weight: 400,
    },
    color: colors.neutral[700],
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
