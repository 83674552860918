import { createUseStyles } from 'react-jss';

const styles = {
  tiersTableBody: {
    margin: {
      top: '2.4rem',
    },
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
