import { Modal, Button, Select, InputText } from '@insurancemenu/imenu-components-lib';
import { OptionType } from '@insurancemenu/imenu-components-lib/dist/types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRootStore } from 'store/rootStore';
import { createStyles } from './styles';

interface Props {
  mode: 'ADD' | 'EDIT';
  show: boolean;
  onClose: () => void;
  onAction: () => void;
  spousesNumber: number;
  childrenNumber: number;
}

const genderOptions: OptionType[] = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

const DependentInfoModal: React.FC<Props> = observer(
  ({ mode, show, onClose, onAction, spousesNumber, childrenNumber }) => {
    // Mobx store
    const {
      dependentInfoStore: {
        form: {
          fields: { relationship, firstName, lastName, middleName, ssn, gender, dateOfBirth },
          onFieldChange,
          isFormValid,
        },
      },
    } = useRootStore();

    // Component actions
    function callOnActionIfFormValid(): void {
      if (isFormValid()) {
        onAction();
      }
    }

    function getRelationshipOptions(spouses: number, children: number): OptionType[] {
      const resultOptions: OptionType[] = [];
      if (spouses < 1) {
        resultOptions.push({ label: 'Spouse', value: 'Spouse' });
      }
      if (children < 4) {
        resultOptions.push({ label: 'Child', value: 'Child' });
      }

      return resultOptions;
    }

    const classes = createStyles();
    return (
      <Modal show={show} onClose={onClose} width="71.2rem" closable={false}>
        <header className={classes.modalHeader}>
          <h2 className={classes.title}>{mode === 'ADD' ? 'Add' : 'Edit'} Dependent</h2>
        </header>
        <div className={classes.modalBody}>
          <Select
            label="Relationship"
            name="relationship"
            options={getRelationshipOptions(spousesNumber, childrenNumber)}
            value={relationship.value}
            onChange={onFieldChange}
            className={classes.longField}
            validationPassed={!relationship.unpassedValidationMsg}
            validationMessage={relationship.unpassedValidationMsg || ''}
          />
          <Select
            label="Gender"
            name="gender"
            options={genderOptions}
            value={gender.value}
            onChange={onFieldChange}
            className={classes.longField}
            validationPassed={!gender.unpassedValidationMsg}
            validationMessage={gender.unpassedValidationMsg || ''}
          />
          <InputText
            label="First Name"
            name="firstName"
            maxLength={30}
            value={firstName.value}
            onChange={onFieldChange}
            validationMessage={firstName.unpassedValidationMsg || ''}
            validationPassed={!firstName.unpassedValidationMsg}
            className={classes.regularField}
          />
          <InputText
            label="Middle Name"
            name="middleName"
            maxLength={90}
            value={middleName.value}
            onChange={onFieldChange}
            validationMessage={middleName.unpassedValidationMsg || ''}
            validationPassed={!middleName.unpassedValidationMsg}
            className={classes.regularField}
          />
          <InputText
            label="Last Name"
            name="lastName"
            maxLength={30}
            value={lastName.value}
            onChange={onFieldChange}
            validationMessage={lastName.unpassedValidationMsg || ''}
            validationPassed={!lastName.unpassedValidationMsg}
            className={classes.regularField}
          />
          <InputText
            label="Date Of Birth"
            name="dateOfBirth"
            htmlType="date"
            value={dateOfBirth.value}
            onChange={onFieldChange}
            validationMessage={dateOfBirth.unpassedValidationMsg || ''}
            validationPassed={!dateOfBirth.unpassedValidationMsg}
            className={classes.longField}
          />
          <InputText
            label="Social Security Number"
            name="ssn"
            value={ssn.value}
            onChange={onFieldChange}
            maxLength={9}
            validationMessage={ssn.unpassedValidationMsg || ''}
            validationPassed={!ssn.unpassedValidationMsg}
            className={classes.longField}
          />
        </div>
        <footer className={classes.modalFooter}>
          <Button type="outlined" onClick={onClose} size="large">
            Cancel
          </Button>
          <Button
            color="primary"
            htmlForm="add-edit-dependent"
            size="large"
            onClick={callOnActionIfFormValid}
            className={classes.actionButton}
          >
            Save
          </Button>
        </footer>
      </Modal>
    );
  },
);

export default DependentInfoModal;
