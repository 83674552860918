import { action, makeObservable, autorun, observable } from 'mobx';
import { toast } from 'react-toastify';

type NotificationType = 'error';

interface NotificationMsg {
  msg?: string;
  title: string;
}

interface SetNotificationParams extends NotificationMsg {
  type: NotificationType;
}

type NotificationRecord = NotificationMsg | null;

export class NotificationStore {
  notifications: Record<NotificationType, NotificationRecord> = {
    error: null,
  };

  public setNotification(notification: SetNotificationParams): void {
    const { type, msg, title } = notification;
    this.notifications[type] = {
      msg,
      title,
    };
  }

  public getNotification(type: NotificationType): NotificationRecord {
    return this.notifications[type];
  }

  constructor() {
    makeObservable(this, {
      notifications: observable,
      setNotification: action,
      getNotification: action,
    });
    autorun(() => {
      const { error } = this.notifications;
      if (error) {
        const { msg = '', title } = error;
        toast.error(`${title}: ${msg}`, {
          toastId: 'error-toast',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  }
}
