import { createUseStyles } from 'react-jss';

const styles = {
  eligibleTiersWrapper: {
    marginBottom: '2rem',
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
