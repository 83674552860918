import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';

const styles = {
  planCardsSection: {
    maxWidth: '109.6rem',
    minHeight: '42rem',
    margin: { bottom: '4rem', left: 'auto', right: 'auto' },
    borderRadius: '1rem',
    backgroundColor: colors.neutral.white,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  title: {
    font: { weight: 700, size: '2.8rem', lineHeight: '4rem' },
    color: colors.neutral[900],
    margin: [0],
    padding: '1.6rem 0',
  },
  innerWrapper: {
    flex: '0 100 80%',
    '@media(max-width: 1124px)': {
      flexBasis: '90%',
    },
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
