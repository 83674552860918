import { createUseStyles } from 'react-jss';

const styles = {
  header: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: { bottom: '5.2rem' },
  },
  title: {
    margin: { top: '5.6rem', bottom: '0.8rem' },
    font: { size: '3.4rem', lineHeight: '4rem', weight: 700 },
    letterSpacing: '0.02rem',
  },
  caption: {
    margin: { bottom: '1.2rem' },
    font: { size: '1.8rem', lineHeight: '2.8rem' },
    letterSpacing: '0.02rem',
  },
  playTutorialButton: { textTransform: 'uppercase' },
  playTutorialLink: {
    textDecoration: 'none',
  },
  backButton: { position: 'absolute', left: '4rem', top: '6.4rem' },
};

const createStyles = createUseStyles(styles);

export { createStyles };
