import { colors } from '@insurancemenu/imenu-components-lib';
import { createUseStyles } from 'react-jss';

const styles = {
  tierCell: {
    padding: '2.4rem 2rem',
    verticalAlign: 'top',
    '&:first-of-type': {
      paddingLeft: '4rem',
    },
    '&:last-of-type': {
      paddingRight: '4rem',
    },
  },
  title: {
    margin: { bottom: '0.8rem' },
    font: { size: '1.4rem', lineHeight: '2rem', weight: 700 },
    letterSpacing: '0.05rem',
    color: colors.neutral[500],
    textTransform: 'uppercase',
  },
  text: { margin: 0, font: { size: '1.6rem', lineHeight: '2.4rem', weight: 500 }, letterSpacing: '0.025rem' },
};

const createStyles = createUseStyles(styles);

export { createStyles };
