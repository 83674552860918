import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'store/rootStore';
import PlanCard from 'components/individual-form/SelectedPlans/PlanCard';
import FormsSpinner from 'components/common/FormSpinner';
import { useQueryParams } from 'global/utils/useQuery';
import { createStyles } from 'components/individual-form/SelectedPlans/styles';

const SelectedPlans = observer(
  (): JSX.Element => {
    const classes = createStyles();
    // Mobx store
    const rootStore = useRootStore();
    const { planSummaryStore } = rootStore;

    const query = useQueryParams();
    const cartId = query.get('cartId') || '';
    const groupId = query.get('groupId') || '';
    const domainId = query.get('domainId') || '';

    // React effects
    useEffect(() => {
      planSummaryStore.populateSelectedPlanItems(groupId, cartId, domainId);
    }, []);

    return (
      <section className={classes.planCardsSection}>
        <div className={classes.innerWrapper}>
          <h1 className={classes.title}>Plan Summary</h1>
          {planSummaryStore.items.map((item) => (
            <PlanCard item={item} key={item.id} />
          ))}
          <FormsSpinner display={planSummaryStore.loading} />
        </div>
      </section>
    );
  },
);

export default SelectedPlans;
