import { gql } from '@apollo/client';

export const GET_ENROLLMENT_PERIOD_BY_ID = gql`
  query ENROLLMENT_SERVICE_getEnrollmentPeriod($periodId: ID!) {
    getEnrollmentPeriod(periodId: $periodId) {
      id
      groupId
      effectiveDate
      hasConfirmedProposal
      proposals {
        cartId
        cartVersion
        name
        productLines {
          id
          productType
          confirmedPortfolioId
        }
        confirmed
      }
      checklist {
        checklistOptions {
          card
          buttonState
          completionStatus
        }
      }
    }
  }
`;

export const APPLY_USER_ACTION = gql`
  mutation ENROLLMENT_SERVICE_applyUserAction($periodId: ID, $userAction: UserActions) {
    applyUserAction(periodId: $periodId, userAction: $userAction) {
      id
    }
  }
`;

export const FORM_EMAIL_ME = gql`
  mutation ENROLLMENT_SERVICE_sendFormEmail($periodId: ID!) {
    sendFormEmail(periodId: $periodId)
  }
`;

export const COMPLETE_ENROLLMENT = gql`
  mutation ENROLLMENT_SERVICE_completeEnrollment($periodId: ID!) {
    completeEnrollment(periodId: $periodId) {
      id
    }
  }
`;
