import { DefaultFormFields, FormControl, FormController } from '@insurancemenu/imenu-components-lib/dist/utils/forms';
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from 'store/rootStore';
import { TierGender, TierRelationship } from 'types/formService';

interface FormFields extends DefaultFormFields {
  relationship: FormControl<TierRelationship | string>;
  gender: FormControl<TierGender | string>;
  firstName: FormControl<string>;
  middleName: FormControl<string>;
  lastName: FormControl<string>;
  dateOfBirth: FormControl<string>;
  ssn: FormControl<string>;
}

export default class DependentInfoStore {
  rootStore: RootStore;

  form: FormController<FormFields>;
  activeTierId: number | undefined;

  setActiveTierId(newId: number | undefined): void {
    this.activeTierId = newId;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.form = new FormController<FormFields>({
      relationship: new FormControl<TierRelationship | string>(''),
      gender: new FormControl<TierGender | string>(''),
      firstName: new FormControl<string>(''),
      middleName: new FormControl<string>('', { optional: true }),
      lastName: new FormControl<string>(''),
      dateOfBirth: new FormControl<string>(''),
      ssn: new FormControl<string>('', { optional: true, validations: ['containsOnlyNumbers'] }),
    });
    this.activeTierId = undefined;

    makeObservable(this, {
      form: observable,
      activeTierId: observable,
      setActiveTierId: action.bound,
    });
  }
}
