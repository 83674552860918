import { gql } from '@apollo/client';

export const GET_FORM_INSTANCE = gql`
  query FORM_SERVICE_getIndividualFormInstances($enrollmentPeriodId: String!) {
    getIndividualFormInstances(enrollmentPeriodId: $enrollmentPeriodId) {
      id
      form {
        id
        formTitle
        description
        sections {
          title
          layout
        }
      }
      enrollmentPeriodId
      status
      answers {
        common
        custom
      }
    }
  }
`;

export const SET_ANSWERS = gql`
  mutation FORM_SERVICE_setAnswers($formInstanceId: String!, $answers: InputFormAnswers!, $complete: Boolean = false) {
    setAnswers(formInstanceId: $formInstanceId, answers: $answers, complete: $complete) {
      id
      status
      answers {
        common
        custom
      }
    }
  }
`;
