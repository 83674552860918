import { createUseStyles } from 'react-jss';

const styles = {
  spinnerWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
};
const createStyles = createUseStyles(styles);

export { styles, createStyles };
