import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';

const styles = {
  formTitle: {
    font: { size: '2.4rem', lineHeight: '3.2rem', weight: 700 },
    letterSpacing: '0.02rem',
    color: colors.neutral[900],
    margin: {
      bottom: '2.4rem',
    },
  },
  formDescription: {
    font: {
      size: '1.6rem',
      lineHeight: '2.4rem',
      weight: 400,
    },
    letterSpacing: '0.02rem',
    margin: { bottom: '4rem' },
  },
  formBody: {
    backgroundColor: colors.neutral.white,
    borderRadius: '10px',
    margin: { bottom: '6.4rem', left: 'auto', right: 'auto' },
    padding: {
      top: '3.2rem',
      bottom: '3.6rem',
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '109.6rem',
    minHeight: '10rem',
    position: 'relative',
  },
  innerWrapper: {
    flex: '0 100 80%',
    '@media(max-width: 1124px)': {
      flexBasis: '90%',
    },
  },
  footer: { display: 'flex', justifyContent: 'center', paddingBottom: '9.6rem' },
  saveButton: { marginRight: '1.6rem' },
};

const createStyles = createUseStyles(styles);

export { createStyles };
