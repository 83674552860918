import { action, makeObservable, observable, runInAction } from 'mobx';
import apolloClient from 'api/apolloClient';
import { Cart } from 'types/cartService';
import { GET_CART_BY_ID } from 'api/cartService';

export const INITIAL_CART_OBJECT = Object.freeze({
  cartItems: [],
  creationDateTime: '',
  groupId: '',
  lastUpdateTime: '',
  browsingCriteria: {
    effectiveDate: '',
    zipCode: '',
  },
  id: '',
});

export class CartStore {
  cart: Cart = INITIAL_CART_OBJECT;

  async getCartById(groupId: string, cartId: string): Promise<void> {
    const res = await apolloClient.query<{ getCartById: Cart }>({
      query: GET_CART_BY_ID,
      fetchPolicy: 'no-cache',
      variables: { groupId, id: cartId },
    });
    runInAction(() => {
      this.cart = res.data.getCartById;
    });
  }

  constructor() {
    makeObservable(this, {
      cart: observable,
      getCartById: action.bound,
    });
  }
}
