import { gql } from '@apollo/client';

export const ENTITY_PORTFOLIOS = gql`
  query PRODUCT_SERVICE_entityPortfoliosByEntityPortfolioRequestEmbedded(
    $entityPortfolioRequest: EntityPortfolioRequest
  ) {
    entityPortfoliosByEntityPortfolioRequestEmbedded(entityPortfolioRequest: $entityPortfolioRequest) {
      id
      portfolio {
        id
        order
        name
      }
      entityProducts {
        id
        product {
          id
          name
          productAttributes {
            id
            productLineAttribute {
              id
              value
              type
            }
            value
            displayValue
            type
          }
          productLine {
            id
            name
          }
          carrier {
            id
            name
            logo
          }
        }
        rates {
          id
          displayKey
          value {
            amount
          }
        }
      }
    }
  }
`;
