import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';

const styles = {
  modal: { textAlign: 'center', padding: '4rem 2.4rem', maxWidth: '65.6rem' },
  icon: { marginBottom: '1.6rem', color: colors.orange[500] },
  text: {
    textAlign: 'center',
    font: { size: '2rem', lineHeight: '2.8rem', weight: 500 },
    letterSpacing: '0.02rem',
    color: colors.neutral[900],
    margin: { bottom: '4rem' },
    borderRadius: '10px',
  },
  modalFooter: {
    display: 'flex',
    padding: '0 13%',
    justifyContent: 'space-between',
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
