import { action, makeObservable, observable, runInAction } from 'mobx';
import apolloClient from 'api/apolloClient';
import { Cart } from 'types/cartService';
import { EntityPortfolio } from 'types/productService';
import { ENTITY_PORTFOLIOS } from 'api/productService';

export class ProductStore {
  entityPortfolios: EntityPortfolio[] = [];

  async requestEntityPortfolios(groupId: string, cart: Cart): Promise<void> {
    const entityPortfolioRequest = JSON.stringify({
      groupId,
      default: {
        groupVersion: 0,
      },
      enrollmentType: 'NEW_SALE',
      portfolios: cart.cartItems.map((item) => {
        return {
          id: item.portfolioId,
          groupVersion: 0,
          productLineName: item.productLineId,
          browsingCriteria: item.browsingCriteria,
        };
      }),
    });

    const res = await apolloClient.query<{ entityPortfoliosByEntityPortfolioRequestEmbedded: [] }>({
      query: ENTITY_PORTFOLIOS,
      variables: { entityPortfolioRequest: JSON.parse(entityPortfolioRequest) },
    });
    runInAction(() => {
      this.entityPortfolios = res.data.entityPortfoliosByEntityPortfolioRequestEmbedded;
    });
  }

  constructor() {
    makeObservable(this, {
      entityPortfolios: observable,
      requestEntityPortfolios: action.bound,
    });
  }
}
