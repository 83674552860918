import { colors } from '@insurancemenu/imenu-components-lib';
import { createUseStyles } from 'react-jss';

const styles = {
  tier: {
    backgroundColor: colors.neutral[100],
    borderRadius: '6px',
    margin: {
      bottom: '2.4rem',
    },
    '&:last-of-type': {
      margin: {
        bottom: '0rem',
      },
    },
  },
};

const createStyles = createUseStyles(styles);

export { createStyles };
