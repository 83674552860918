import React from 'react';
import { createStyles } from './styles';

const ConfirmationPage = (): JSX.Element => {
  const classes = createStyles();
  return (
    <main className={classes.content}>
      <h1 className={classes.title}>Thank you for your submission!</h1>
      <p className={classes.text}>
        You will receive your completed application with instructions on where to send it in. If you have any questions,
        you can call XXX-XXX-XXXX.
      </p>
    </main>
  );
};

export default ConfirmationPage;
