import React from 'react';
import { Table, TableBody } from '@insurancemenu/imenu-components-lib';
import { Tier } from 'types/formService';
import { createStyles } from './styles';
import TiersTableRow from '../TiersTableRow';

interface Props {
  tiers: Tier[];
  onEditTier: (tier: Tier) => void;
  onDeleteTier: (id: number) => void;
}

const TiersTable: React.FC<Props> = ({ tiers, onEditTier, onDeleteTier }) => {
  const classes = createStyles();
  return (
    <Table>
      <TableBody className={classes.tiersTableBody}>
        {tiers.map((tier) => (
          <TiersTableRow key={tier.id} {...tier} onEdit={onEditTier} onDelete={onDeleteTier} />
        ))}
      </TableBody>
    </Table>
  );
};

export default TiersTable;
